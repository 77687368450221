var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-priceEstimation"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('PriceEstimation')))]), _vm.fileName ? _c('span', {
    staticClass: "text-wrap display-1 my-3"
  }, [_vm._v(" - " + _vm._s(_vm.fileName) + " ")]) : _vm._e(), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('SelectPart')) + " ")], 1), _c('input', {
    ref: "uploadSTL",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".stl,model/stl,.3mf"
    },
    on: {
      "change": _vm.fileSelected
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-6"
  }), _c('v-row', [_c('v-col', {
    class: {
      'd-flex': !_vm.partFile,
      'align-center': !_vm.partFile,
      'justify-center': !_vm.partFile
    },
    attrs: {
      "cols": _vm.fullscreen ? 12 : 8
    }
  }, [_c('v-card', [_vm.partFile ? _c('v-card-text', {
    staticClass: "pt-6"
  }, [_vm.partFile ? _c('Viewer3d', {
    attrs: {
      "part-file": _vm.partFile,
      "part": _vm.part,
      "background": "#ffffff",
      "mesh": "#9E9E9E",
      "toolbar": true,
      "clipping": true
    },
    on: {
      "fullscreen": _vm.switchFullscreen
    }
  }) : _vm._e()], 1) : _c('v-card-text', [_c('div', [!_vm.loading ? _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('SelectPart')) + " ")], 1) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "py-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    staticClass: "ma-10",
    attrs: {
      "color": "orange",
      "size": "170",
      "width": 8,
      "indeterminate": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("../../assets/logo.png"),
      "contain": "",
      "aspect-ratio": "1",
      "width": "110"
    }
  })], 1)], 1)], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.fullscreen ? 6 : 4
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.expandedPanels,
      callback: function callback($$v) {
        _vm.expandedPanels = $$v;
      },
      expression: "expandedPanels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "headerTitle"
  }, [_vm._v(_vm._s(_vm.$t('PartInformation')))]), _c('v-spacer'), _c('v-icon', [_vm._v("fas fa-info")])], 1), _c('v-expansion-panel-content', [_c('div', {
    staticClass: "text-center d-flex align-center justify-center mb-4"
  }, [_c('div', [_vm.part.size ? _c('span', {
    staticClass: "heightCube"
  }, [_vm._v(" " + _vm._s(_vm.part.size.height) + " mm ")]) : _c('span', {
    staticClass: "heightCube"
  }, [_vm._v("--- mm")]), _vm.part.size ? _c('span', {
    staticClass: "widthCube"
  }, [_vm._v(" " + _vm._s(_vm.part.size.width) + " mm ")]) : _c('span', {
    staticClass: "widthCube"
  }, [_vm._v("--- mm")]), _vm.part.size ? _c('span', {
    staticClass: "depthCube"
  }, [_vm._v(" " + _vm._s(_vm.part.size.depth) + " mm ")]) : _c('span', {
    staticClass: "depthCube"
  }, [_vm._v("--- mm")]), _c('v-img', {
    staticClass: "imageCube",
    attrs: {
      "src": require("../../../public/img/icons/cube_size_arrows-svg.svg"),
      "alt": "cube sizes",
      "max-width": "130px",
      "height": "auto"
    }
  })], 1)]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_vm.part.volume ? _c('span', {
    staticClass: "volume"
  }, [_vm._v(" " + _vm._s(_vm.improveVolumeReadability(_vm.part.volume)) + " ")]) : _c('span', {
    staticClass: "volume"
  }, [_vm._v("--- cm³")]), _c('v-img', {
    attrs: {
      "src": require("../../../public/img/icons/volume.png"),
      "alt": "volume",
      "max-height": "110px",
      "contain": ""
    }
  })], 1)])], 1), _vm.supplier ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "headerTitle"
  }, [_vm._v(_vm._s(_vm.$t('PrintingInformation')))]), _c('v-spacer'), _vm.selectedTechnology && _vm.selectedMaterial ? _c('v-icon', [_vm._v(" fas fa-pencil-ruler ")]) : _c('v-icon', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v(" fas fa-pencil-ruler ")])], 1), _c('v-expansion-panel-content', [_c('v-select', {
    attrs: {
      "items": _vm.lists.technologies,
      "label": _vm.$t('Technology'),
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        _vm.selectedMaterial = null;
      }
    },
    model: {
      value: _vm.selectedTechnology,
      callback: function callback($$v) {
        _vm.selectedTechnology = $$v;
      },
      expression: "selectedTechnology"
    }
  }), _c('v-select', {
    attrs: {
      "disabled": !_vm.selectedTechnology,
      "items": _vm.lists[_vm.selectedTechnology],
      "label": _vm.$t('Material'),
      "outlined": ""
    },
    model: {
      value: _vm.selectedMaterial,
      callback: function callback($$v) {
        _vm.selectedMaterial = $$v;
      },
      expression: "selectedMaterial"
    }
  })], 1)], 1) : _vm._e()], 1), _vm.part && _vm.part.volume > 0 && _vm.selectedTechnology && _vm.selectedMaterial && _vm.supplier ? _c('PriceEstimationTable', {
    staticClass: "mt-4",
    attrs: {
      "part": _vm.part,
      "technology": _vm.selectedTechnology,
      "material": _vm.selectedMaterial,
      "prices-configurations": _vm.supplier.prices,
      "price-multiplier-percentage": 100
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }