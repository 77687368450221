<template lang="html" src="./pagePriceEstimation.template.vue"></template>

<style lang="scss" src="./pagePriceEstimation.scss"></style>

<script>
import { ApiErrorParser, Viewer3d, Utils3MF, UtilsSTL } from '@cloudmanufacturingtechnologies/portal-components';

import PriceEstimationTable from '../../components/priceEstimationTable/PriceEstimationTable';

const i18nData = require('./pagePriceEstimation.i18n');

export default {
  name: 'PagePriceEstimation',
  components: {
    Viewer3d,
    PriceEstimationTable,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Main variable
       */
      supplier: null,
      part: {
        size: {
          width: 0,
          depth: 0, 
          height: 0,
        },
        nestingSize: {
          width: 0,
          depth: 0, 
          height: 0,
        },
        volume: 0,
        rotationHistory: [{matrix: [0,0,0]}],
        criticalDimensions: []
      },
      lists: null,
      selectedTechnology: null,
      selectedMaterial: null,
      /**
       * Panel
       */
      expandedPanels: [0, 1],
      /**
       * File variable
       */
      partFile: null,
      fileName: null,
      /**
       * 3DViewer variable
       */
      fullscreen: false,
      loading: false,
      partGeometry: null,
    };
  },
  created() {
    this.$apiInstance.getSupplier(this.$route.params.supplierUUID)
      .then(supplier => {
        this.supplier = supplier;
        /**
         * Create list
         */
        const lists = {};
        lists.technologies = [];
        Object.keys(supplier.technologies).forEach((technology) => {
          lists.technologies.push({
            text: this.$t(technology),
            value: technology
          });
          lists[technology] = [];
          supplier.technologies[technology].forEach(material => {
            lists[technology].push({
              text: this.$t(material),
              value: material,
            });
          });
        });
        this.lists = lists;
      })
      .catch((error) => {
        this.$notification.notify('DANGER',  this.$t(ApiErrorParser.parse(error)));
      });
    this.quantities = [1, 2, 3, 4, 5, 10, 20, 50, 100, 250, 500, 1000];
  },
  mounted() {},
  methods: {
    openFileSelector() {
      const fileSelector = this.$refs.uploadSTL;
      fileSelector.value = '';
      fileSelector.click();
    },
    fileSelected() {
      const fileSelector = this.$refs.uploadSTL;
      if (fileSelector.value && fileSelector.files.length > 0) {
        this.loading = true;
        this.partFile = null;
        this.fullscreen = false;
        this.partVolume = null;
        this.partSize = null;
        this.fileName = null;
        const file = fileSelector.files[0];
        const splittedFileName = file.name.split('.');
        const extension = splittedFileName.pop().toLocaleLowerCase();
        if (
          splittedFileName.length > 0 &&
          extension === 'stl'
        ) {
          this.fileName = file.name;
          const fileReader = new FileReader();
          fileReader.addEventListener('loadend', () => {
            setTimeout(() => {
              this.partFile = 
                {
                  buffer: Buffer.from(fileReader.result),
                  extension: 'stl'
                };
              this.loading = false;
              this.loadGeometry();
              if (!this.expandedPanels.includes(2)) {
                this.expandedPanels.push(2);
              }
            }, 200);
          });
          fileReader.readAsArrayBuffer(file);
        } else if (
          splittedFileName.length > 0 && 
          extension === '3mf'
        ) {
          this.fileName = file.name;
          const fileReader = new FileReader();
          fileReader.addEventListener('loadend', () => {
            setTimeout(() => {
              this.partFile = 
                {
                  buffer: Buffer.from(fileReader.result),
                  extension: '3mf'
                };
              this.loading = false;
              this.loadGeometry();
              if (!this.expandedPanels.includes(2)) {
                this.expandedPanels.push(2);
              }
            }, 200);
          });
          fileReader.readAsArrayBuffer(file);
        }
      }
    },
    switchFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    loadGeometry() {
      if(this.partFile.extension === 'stl') {
        const bufferGeom = UtilsSTL.loadSTLAsBufferGeometry(this.partFile.buffer);
        bufferGeom.center();
        bufferGeom.computeVertexNormals();
        bufferGeom.computeBoundingBox();
        this.partGeometry = bufferGeom;
        this.getPartSize();
        this.getPartVolume();
      } else if(this.partFile.extension === '3mf') {
        const bufferGeom = Utils3MF.load3mfAsBufferGeometry(this.partFile.buffer);
        bufferGeom.center();
        bufferGeom.computeVertexNormals();
        bufferGeom.computeBoundingBox();
        this.partGeometry = bufferGeom;
        this.getPartSize();
        this.getPartVolume();
      }
    },
    getPartSize() {
      const bb = this.partGeometry.boundingBox;
      this.part.size = {
        width: Math.round(bb.max.x - bb.min.x),
        depth: Math.round(bb.max.y - bb.min.y),
        height: Math.round(bb.max.z - bb.min.z),
      };
      this.part.nestingSize = this.part.size;
    },
    /**
     * Analyse Geometry
     */
    getPartVolume() {
      const coordinates = this.splitRawData(
        this.partGeometry.attributes.position.array
      );
      const precision = 10000;
      const triangles = this.constructTriangles(coordinates);
      this.part.volume = this.volumeOfPart(triangles, precision);
    },

    splitRawData(data) {
      const cX = [];
      const cY = [];
      const cZ = [];

      for (let i = 0; i < data.length; i++) {
        if (i % 3 === 0) {
          cX.push(data[i]);
        } else if (i % 3 === 1) {
          cY.push(data[i]);
        } else {
          cZ.push(data[i]);
        }
      }

      return [cX, cY, cZ];
    },

    /**
     * Construct triangles from split raw coordinates
     */
    constructTriangles(coordinates) {
      const cX = coordinates[0];
      const cY = coordinates[1];
      const cZ = coordinates[2];

      const triangles = [];

      for (let i = 0; i < cX.length; i = i + 3) {
        triangles[i / 3] = [
          [cX[i], cY[i], cZ[i]],
          [cX[i + 1], cY[i + 1], cZ[i + 1]],
          [cX[i + 2], cY[i + 2], cZ[i + 2]],
        ];
      }

      return triangles;
    },

    /**
     * Compute volume for a single triangle of the mesh
     */
    volumeOfTriangle(triangle) {
      const v321 = triangle[2][0] * triangle[1][1] * triangle[0][2]; //p3.X*p2.Y*p1.Z;
      const v231 = triangle[1][0] * triangle[2][1] * triangle[0][2]; //p2.X*p3.Y*p1.Z;
      const v312 = triangle[2][0] * triangle[0][1] * triangle[1][2]; //p3.X*p1.Y*p2.Z;
      const v132 = triangle[0][0] * triangle[2][1] * triangle[1][2]; //p1.X*p3.Y*p2.Z;
      const v213 = triangle[1][0] * triangle[0][1] * triangle[2][2]; //p2.X*p1.Y*p3.Z;
      const v123 = triangle[0][0] * triangle[1][1] * triangle[2][2]; //p1.X*p2.Y*p3.Z;

      return (-v321 + v231 + v312 - v132 - v213 + v123) / 6.0;
    },

    /**
     * Compute the total volume of a part from its triangles
     */
    volumeOfPart(triangles, precision) {
      let volume = 0;
      for (const triangle of triangles) {
        volume += this.volumeOfTriangle(triangle);
      }

      return Math.round(Math.abs(volume) * precision) / precision;
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
  },
};
</script>
